<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>
		<!-- Counter Widgets -->
		<a-row :gutter="24">
			<a-col :span="24" :lg="16" :xl="6" class="mb-24" v-for="(stat, index) in stats" :key="index">
				<!-- Widget 1 Card -->
				<WidgetCounter :title="stat.title" :value="stat.value" :prefix="stat.prefix" :suffix="stat.suffix"
					:icon="stat.icon" :status="stat.status"></WidgetCounter>
				<!-- / Widget 1 Card -->
			</a-col>
		</a-row>
		<!-- / Counter Widgets -->

		<!-- Charts -->
		<a-row :gutter="24" type="flex" align="stretch">
			<a-col :span="24" :lg="10" class="mb-24">

				<!-- Active Users Card -->
				<CardBarChart></CardBarChart>
				<!-- Active Users Card -->

			</a-col>
			<!-- Timeline -->
			<a-col :span="24" :lg="14" class="mb-24">

				<!-- Orders History Timeline Card -->
				<CardOrderHistory :dashboardData="dashboardData"></CardOrderHistory>
				<!-- / Orders History Timeline Card -->

			</a-col>
			<!-- / Timeline -->
		</a-row>
		<!-- / Charts -->

		<!-- Table & Timeline -->

		<!-- Cards -->
		<a-row :gutter="24" type="flex" align="stretch">
			<a-col :span="24" :xl="14" class="mb-24">

				<!-- Information Card 1 -->
				<CardInfo></CardInfo>
				<!-- / Information Card 1 -->

			</a-col>
			<a-col :span="24" :xl="10" class="mb-24">

				<!-- Information Card 2 -->
				<CardInfo2></CardInfo2>
				<!-- / Information Card 2 -->

			</a-col>
		</a-row>
		<!-- / Cards -->

	</div>
</template>

<script>

// bruh
import axios from 'axios';
// Bar chart for "Active Users" card.
import CardBarChart from '../components/Cards/CardBarChart';

// Line chart for "Sales Overview" card.
import CardLineChart from '../components/Cards/CardLineChart';

// Counter Widgets
import WidgetCounter from '../components/Widgets/WidgetCounter';

// "Projects" table component.
import CardProjectTable from '../components/Cards/CardProjectTable';

// Order History card component.
import CardOrderHistory from '../components/Cards/CardOrderHistory';

// Information card 1.
import CardInfo from '../components/Cards/CardInfo';

// Information card 2.
import CardInfo2 from '../components/Cards/CardInfo2';

// Counter Widgets stats
const stats = [
	/* 		{
				title: "Today’s Sales",
				value: 53000,
				prefix: "$",
				suffix: "+30%",
				icon: `
							<svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M8.43338 7.41784C8.58818 7.31464 8.77939 7.2224 9 7.15101L9.00001 8.84899C8.77939 8.7776 8.58818 8.68536 8.43338 8.58216C8.06927 8.33942 8 8.1139 8 8C8 7.8861 8.06927 7.66058 8.43338 7.41784Z" fill="#111827"/>
								<path d="M11 12.849L11 11.151C11.2206 11.2224 11.4118 11.3146 11.5666 11.4178C11.9308 11.6606 12 11.8861 12 12C12 12.1139 11.9308 12.3394 11.5666 12.5822C11.4118 12.6854 11.2206 12.7776 11 12.849Z" fill="#111827"/>
								<path fill-rule="evenodd" clip-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 5C11 4.44772 10.5523 4 10 4C9.44772 4 9 4.44772 9 5V5.09199C8.3784 5.20873 7.80348 5.43407 7.32398 5.75374C6.6023 6.23485 6 7.00933 6 8C6 8.99067 6.6023 9.76515 7.32398 10.2463C7.80348 10.5659 8.37841 10.7913 9.00001 10.908L9.00002 12.8492C8.60902 12.7223 8.31917 12.5319 8.15667 12.3446C7.79471 11.9275 7.16313 11.8827 6.74599 12.2447C6.32885 12.6067 6.28411 13.2382 6.64607 13.6554C7.20855 14.3036 8.05956 14.7308 9 14.9076L9 15C8.99999 15.5523 9.44769 16 9.99998 16C10.5523 16 11 15.5523 11 15L11 14.908C11.6216 14.7913 12.1965 14.5659 12.676 14.2463C13.3977 13.7651 14 12.9907 14 12C14 11.0093 13.3977 10.2348 12.676 9.75373C12.1965 9.43407 11.6216 9.20873 11 9.09199L11 7.15075C11.391 7.27771 11.6808 7.4681 11.8434 7.65538C12.2053 8.07252 12.8369 8.11726 13.254 7.7553C13.6712 7.39335 13.7159 6.76176 13.354 6.34462C12.7915 5.69637 11.9405 5.26915 11 5.09236V5Z" fill="#111827"/>
							</svg>`,
			}, */
	{
		title: "Новые польз. сегодня",
		value: 0,
		suffix: "",
		icon: `
						<svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z" fill="#111827"/>
							<path d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z" fill="#111827"/>
							<path d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z" fill="#111827"/>
							<path d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z" fill="#111827"/>
						</svg>`,
	},
	{
		title: "Новые вопросы сегодня",
		value: 0,
		prefix: "+",
		status: "danger",
		suffix: "",
		icon: `
						<svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M3.17157 5.17157C4.73367 3.60948 7.26633 3.60948 8.82843 5.17157L10 6.34315L11.1716 5.17157C12.7337 3.60948 15.2663 3.60948 16.8284 5.17157C18.3905 6.73367 18.3905 9.26633 16.8284 10.8284L10 17.6569L3.17157 10.8284C1.60948 9.26633 1.60948 6.73367 3.17157 5.17157Z" fill="#111827"/>
						</svg>`,
	},
	{
		title: "Отвечено сегодня",
		value: 0,
		prefix: "",
		suffix: "",
		icon: `
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  							<path id="path3833" style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:150px;line-height:125%;font-family:Arial;-inkscape-font-specification:'Arial Bold';letter-spacing:0px;word-spacing:0px;opacity:0.8;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.201503" d="m 10.969169,0.5428226 c -2.3648946,0 -4.2751314,0.5882658 -5.7300785,1.765183 C 3.7841433,3.4847299 3.000127,4.9157718 2.8870417,6.6011311 L 6.866298,7.0528868 C 7.1440625,5.8761624 7.6530519,5.0006085 8.3934766,4.4262251 9.1336907,3.8520345 10.053957,3.5649392 11.154275,3.5649392 c 1.141383,0 2.048803,0.2753339 2.722261,0.8260015 0.673458,0.5506677 1.010187,1.2122016 1.010187,1.9840233 0,0.5554879 -0.19016,1.06393 -0.570481,1.5253263 -0.246808,0.2917228 -1.002605,0.9085245 -2.267392,1.8498269 -1.264787,0.9414948 -2.1079785,1.7888988 -2.5295741,2.5420178 -0.4215956,0.753312 -0.6323934,1.71351 -0.6323934,2.880979 0,0.112987 0.00505,0.428233 0.015373,0.946123 h 3.9331375 c -0.02064,-1.092081 0.07981,-1.85002 0.300929,-2.273625 0.220906,-0.423799 0.789071,-0.983914 1.704283,-1.680539 1.768511,-1.346398 2.92274,-2.4101355 3.462685,-3.1915977 0.539736,-0.7814622 0.809708,-1.6097775 0.809708,-2.4853314 0,-1.5816271 -0.73516,-2.9679368 -2.20569,-4.1589291 -1.47032,-1.1909924 -3.44984,-1.7863922 -5.938139,-1.7863922 z M 8.9022553,17.488965 v 3.968239 h 4.3340957 v -3.968239 z" />
						</svg>`,
	},
];

export default ({
	components: {
		CardBarChart,
		CardLineChart,
		WidgetCounter,
		CardProjectTable,
		CardOrderHistory,
		CardInfo,
		CardInfo2,
	},
	methods: {
		widgetSetValueAndPrefix(stat, value) {
			stat.value = value;
			if (stat.value > 0) {
				stat.prefix = "+";
				return;
			}
			if (stat.value < 0) {
				stat.prefix = "-";
				return;
			}
			stat.prefix = " ";
		},
	},
	created() {
		axios
			.get("/api/v1/dashboard")
			.then((response) => {
				this.dashboardData = response.data;
				this.widgetSetValueAndPrefix(stats[0], response.data.TodayUsers);
				this.widgetSetValueAndPrefix(stats[1], response.data.TodayTickets);
				this.widgetSetValueAndPrefix(stats[2], response.data.TodayAnswers)
			})
			.catch((error) => {
				console.error("error");
				console.log(error);
			});
	},
	data() {
		return {

			// Counter Widgets Stats
			stats,

			// Dashboard data from backend
			dashboardData: {},
		}
	},
})

</script>

<style lang="scss">
</style>
